const getMainColumn = (pageContents) => {
  return pageContents?.mainColumn?.contents;
};

const getRightColumn = (pageContents) => {
  return pageContents?.rightColumn?.contents;
};

export default {
  getMainColumn,
  getRightColumn,
};
