import React, { useState, useEffect, useRef } from "react";
import Link from "next/link";
import ApiImage from "../ApiImage";
import styles from "./PageHeader.module.scss";
import OnPageNavigation from "../OnPageNavigation/OnPageNavigation";
import useScrollingWindow from "../../../../../hooks/useScrollWindow";
import Icon from "../../../../../components/Icon";
import PageHeaderImage from "../PageHeaderImage/PageHeaderImage";
import Breadcrumb from "../../../../../components/Breadcrumb/Breadcrumb";
import { HEADER_EVENTS } from "../../../../../components/Header";
import PagePathUtility from "../../../../../libs/PagePathUtility";

const PageHeader = ({
  headerImage,
  headerImageTitle = "",
  logo,
  showOnPageNav,
  contents,
  rootline,
  hideBreadcrumb = false,
  headerImageOverlay = false,
  preview,
  previewData,
}) => {
  let prevScrollY = useRef(0);
  const [offsetTopNav, updateOffsetTop] = useState(0);
  const [mobileNav, updateNav] = useState(true);
  const [mobileNavShow, updateNavShow] = useState(false);

  // custom hook to listen scrolling event
  const [stateScrolling] = useScrollingWindow(offsetTopNav);

  const scrollListener = () => {
    if (!offsetTopNav) {
      return;
    }

    const currentScrollY = window.scrollY;

    if (currentScrollY > offsetTopNav) {
      updateNav(false);
    } else {
      updateNav(true);
    }

    if (
      currentScrollY > offsetTopNav + 170 &&
      !(prevScrollY.current > currentScrollY)
    ) {
      updateNavShow(true);
    } else {
      updateNavShow(false);
    }
    prevScrollY.current = currentScrollY;
  };

  useEffect(() => {
    if (showOnPageNav) {
      updateOffsetTop(document.getElementById("on-page-nav").offsetTop);
      window.addEventListener("scroll", scrollListener);
      return () => {
        window.removeEventListener("scroll", scrollListener);
      };
    }
  }, [stateScrolling]);

  const handlerMobileNav = (e) => {
    e.preventDefault();
    updateNav((mobileNav) => !mobileNav);
  };

  const handleMainMenuOpen = () => {
    let event = new Event(HEADER_EVENTS.TOGGLE_MAIN_MENU);
    document.dispatchEvent(event);
  };

  return (
    <>
      {!hideBreadcrumb && <Breadcrumb items={rootline} />}
      {headerImage && (
        <PageHeaderImage
          image={headerImage}
          overlayText={headerImageTitle}
          overlay={headerImageOverlay}
        />
      )}

      {showOnPageNav && (
        <div className={`page_header_on_page_nav ${styles.onPageNavigation}`}>
          <div
            id={"on-page-nav"}
            className={`grid-x ${styles.onPageNavigationWrapper} ${
              stateScrolling ? styles.scrolling : ""
            } ${mobileNavShow ? styles.hidden : ""}`}
          >
            <div
              className={`cell text-center ${styles.logoCell} ${
                stateScrolling ? styles.scrolling : ""
              }`}
            >
              {/* Logo LTO */}

              <Link href="/" prefetch={false}>
                <a
                  className={`${styles.ltoLink} ${
                    stateScrolling ? styles.scrolling : ""
                  }`}
                >
                  <img
                    className={`${styles.ltoLogo}`}
                    src={PagePathUtility.createLinkWithBasepath(
                      "/static/img/lto-karriere_logo-with-underline.svg"
                    )}
                    alt="logo lto karriere"
                    width={40}
                    height={34}
                  />
                </a>
              </Link>
              {logo && (
                <ApiImage
                  className={`${styles.logoCompany} ${
                    stateScrolling && styles.scrolling
                  }`}
                  image={logo}
                  height={60}
                />
              )}
              {/* Burger to open menu */}
              <a
                className={`${styles.mobileNavButton} ${
                  mobileNav ? styles.show : ""
                }`}
                onClick={handlerMobileNav}
              >
                <span className={styles.mobileNavButtonText}>Menü</span>
                <Icon name={"add"} size={"default"} />
              </a>
            </div>
            <OnPageNavigation
              contents={contents}
              mobileNav={mobileNav}
              onScrollEnd={() => updateNavShow(true)}
            />
            <a
              className={`cell item initial burger ${styles.burger}`}
              onClick={handleMainMenuOpen}
            >
              <span className="page-header__burger">
                <span className="page-header__burger-top"></span>
                <span className="page-header__burger-middle"></span>
                <span className="page-header__burger-bottom"></span>
              </span>
              <span className="page-header__burger-text">Menu</span>
            </a>
            {preview && previewData.type === "employerEdit" && (
              <div
                style={{
                  position: "absolute",
                  width: "200px",
                  height: "200px",
                  transform: "rotate(-45deg)",
                  top: "0",
                  left: "0",
                  zIndex: "999",
                }}
              >
                <span
                  style={{
                    position: "absolute",
                    top: "0",
                    right: "0",
                    width: "200px",
                    fontSize: ".8rem",
                    fontWeight: "700",
                    textAlign: "center",
                    padding: "0.5rem 0",
                    backgroundColor: "#df1051",
                    color: "#fff",
                  }}
                >
                  Editiermodus
                </span>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default PageHeader;
