import CropApiImage from "../CropApiImage";
import styles from "./PageHeaderImage.module.scss";

const PageHeaderImage = ({ image, overlayText, cssName, overlay = false }) => (
  <div
    className={`page_header_image_wrapper ${styles.headerImageWrapper} ${cssName}`}
  >
    {overlayText && <h1 className={styles.header}>{overlayText}</h1>}
    <CropApiImage image={image} className={styles.headerImage} priority />
    {overlay && <div className={`${styles.overlay} overlay overlay--black`} />}
  </div>
);

export default PageHeaderImage;
